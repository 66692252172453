import React, { useEffect, useRef, useState, useCallback } from "react";
import ReportLicenseButton from "./ReportLicenseButton";
import Api from "../../Api";
import Pagination from "../common/Pagination";
import { useIsMount } from "../../common/customHook";
import ReportTable from "./ReportTable";
import { VIEW } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import LoadingSpinner from "../common/LoadingSpinner";
import { IsMobile } from "../common/MediaQuery";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";

export default function ReportListBased(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [state, setState] = useState({ isLoading: false });
    const dataLength = useRef(-1);
    const isMobile = IsMobile();
    const filterOption = props.filterOption;
    const basedOption = props.basedOption;
    const paginationOption = useRef({ itemsCountPerPage: 16, pageRangeDisplayed: isMobile ? 5 : 10, totalItemsCount: 0 });
    const isMount = useIsMount();
    const navigate = useNavigate();
    const isSiteMember = common.hasSiteMemberPermission();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const prevFilterOption = useRef({});

    useEffect(() => {
        if (!Object.keys(filterOption).length) {
            paginationOption.current.totalItemsCount = 0;
            setData([]);
        }
    }, [filterOption]);

    const getListBasedData = useCallback((page) => {
        setState((prev) => ({ ...prev, isLoading: true }));

        // update startDate
        let newStartDate = null;
        if (filterOption.startDate) {
            // update date into local one
            newStartDate = common.convertToIsoDate(filterOption.startDate);
        }

        // update endDate
        let newEndDate = null;
        if (filterOption.endDate) {
            // update date into local one
            newEndDate = common.convertToIsoDate(filterOption.endDate, 1);
        }

        // 선택된 항목들을 구분자(|)로 나누어 array로 생성
        const groupsArray = filterOption.groups ? filterOption.groups.split("|") : undefined;
        const projectsArray = filterOption.projects ? filterOption.projects.split("|") : undefined;
        const locationsArray = filterOption.locations ? filterOption.locations.split("|") : undefined;

        const payload = {
            groups: groupsArray,
            projects: projectsArray,
            locations: locationsArray,
            startDate: filterOption.startDate ? newStartDate : undefined,
            endDate: filterOption.endDate ? newEndDate : undefined,
            registered_by: filterOption.name,
            keyword: filterOption.keyword,
            page: page ? page : 1,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            user_id: filterOption.myReport === "true" ? sessionStorage.getItem("_id") : undefined,
        };

        Api.getReportsList(payload)
            .then((payload) => {
                if (!isMount.current) return;
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                dataLength.current = payload.reports.length;
                setData(payload.reports);
            })
            .catch((e) => {
                dgLogger.error(e)();
                // show empty data
                paginationOption.current.totalItemsCount = 0;
                dataLength.current = 0;
                setData([]);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isLoading: false }));
            });
    }, [filterOption, isMount]);

    // page 또는 filterOption이 바뀌었을 때 현재 filterOption에 따라 사용 할 data 요청
    useEffect(() => {
        if (basedOption.page && JSON.stringify(prevFilterOption.current) !== JSON.stringify(filterOption)) {
            getListBasedData(basedOption.page);
            prevFilterOption.current = filterOption;
        }
    }, [filterOption, basedOption.page, getListBasedData]);

    useEffect(() => {
        resetCheckbox();

        if (document.getElementById("all-check")) {
            if (data.find((item) => item.no_request_history)) {
                document.getElementById("all-check").disabled = false;
            }
            else {
                document.getElementById("all-check").disabled = true;
            }
        }
    }, [data, state.isLoading]);

    useEffect(() => {
        paginationOption.current.pageRangeDisplayed = isMobile ? 5 : 10;
        forceUpdate();
    }, [isMobile, forceUpdate]);

    /**
     * page를 변경 할 때 실행 되는 callback function
     * page 번호를 url에 넣어 navigate
     */
    const pageChange = useCallback((page) => {
        let param = props.createDefaultParam(VIEW.ListBased);
        param += `/page=${page}`;
        navigate(param);
    }, [props, navigate]);

    /**
    * 전체 checkbox를 초기화 해주는 함수
    */
    const resetCheckbox = () => {
        if (!document.getElementById("all-check")) return;
        document.getElementById("all-check").checked = false;
        document.getElementById("request-report-license").disabled = true;
        for (let i = 0; i < paginationOption.current.itemsCountPerPage; i++) {
            if (document.getElementById("report-card-checkbox" + i))
                document.getElementById("report-card-checkbox" + i).checked = false;
        }
    };

    /**
    * 자료의 사용권을 신청했을 때 실행되는 함수
    */
    const requestReportLicense = (payload) => {
        payload.reportIds = [];
        for (let i = 0; i < data.length; i++) {
            if (document.getElementById("report-card-checkbox" + i) && document.getElementById("report-card-checkbox" + i).checked) {
                payload.reportIds.push(data[i]._id);
            }
        }
        payload.lang = common.getLang();

        Api.requestReportLicense(payload)
            .then(() => {
                // getListBasedData();
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    if (state.isLoading) return <LoadingSpinner isOpen={true} />;

    return (
        <>
            {isSiteMember && data?.length ?
                <ReportLicenseButton
                    itemsCountPerPage={paginationOption.current.itemsCountPerPage}
                    requestReportLicense={requestReportLicense}
                    resetCheckbox={resetCheckbox}
                    id_prefix={"report-card-checkbox"}
                    button_id={"request-report-license"}
                />
                : <div style={{ height: "47px" }} />}
            {dataLength?.current === 0 ?
                <div id="empty-report-text" className="notosanskr-500 font-size-40" style={{ textAlign: "center" }}>
                    {t("36")}
                </div>
                :
                <ReportTable
                    reports={data}
                    checkbox={isSiteMember}
                    itemsCountPerPage={paginationOption.current.itemsCountPerPage}
                />
            }

            {dataLength?.current > 0 &&
                <Pagination
                    ref={pageChange}
                    page={basedOption.page ? parseInt(basedOption.page) : -1}
                    itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                    totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                    pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    hrefBuilder={(page, pageCount, selected) => { // page 버튼에 href link 생성
                        let param = props.createDefaultParam(VIEW.ListBased);
                        param += `/page=${page}`;
                        return param;
                    }}
                />
            }
        </>
    );
}
