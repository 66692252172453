import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { error as E } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import Api from "../../Api";
import { POPUP as GeneralPopup } from "../../common/defines";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "./SignIn.css";
import { InputText } from "../Input";
import CustomCheckbox from "../common/CustomCheckbox";
import CustomButtonLink from "../common/CustomButtonLink";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import { GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../common/fbase.js";
import { isRunOnMobileAppWebview, sendMessageToMobileAppWebview, setHandleMobileAppWebviewMessages } from "../common/RNBridge.js";

const ID = {
    email: "signin-email",
    password: "signin-password",
    autoLogin: "signin-auto-login",
    loginFailure: "signin-login-failure",
    loginFailureReason: "signin-login-failure-reason",
};

const POPUP = {
    ...GeneralPopup,
    LoginFailure: 1046,
    FirebaseAuthFailure: 1047,
    UpdateRealname: 1048,
};

export default function SignIn(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({ popup: POPUP.None, popupTarget: null });
    const navigate = useNavigate();
    const context = useContext(AppContext);
    // const {mutate} = useSWRConfig();
    const [errors, setErrors] = useState({email: '', password: ''});

    const googleProvider = useCallback(() => {
        const provider = new GoogleAuthProvider();
        return provider;
    }, []);

    const appleProvider = useCallback(() => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        return provider;
    }, []);

    const login = useCallback((authProvider = "email", email, password) => {
        const autoLogin = document.getElementById(ID.autoLogin).checked;

        Api.signInV2({
            authProvider: authProvider,
            email: email,
            password: password,
            autoLogin: autoLogin,
            displayLang: common.getLang(),
        })
            .then((payload) => {
                return Api.getCurrentUserInfo({ optPermissions: true });
            })
            .then(async (payload) => {
                if (!payload.realname) {
                    return setState(prev => ({...prev, isLoading: false, popup: POPUP.UpdateRealname, popupTarget: {...payload, autoLogin: autoLogin, password: password} }));
                }

                sendMessageToMobileAppWebview({type: "logged-in", login: true, _id: payload._id});

                const isReportSnapshot = await Api.fetchReportSnapshot({ registered_by: payload._id, as_boolean: true });
                if (isReportSnapshot) {
                    localStorage.setItem(payload._id, isReportSnapshot);
                }
                else {
                    localStorage.removeItem(payload._id);
                }

                sessionStorage.setItem("name", payload.name);
                sessionStorage.setItem("_id", payload._id);
                common.storePermissionsInfo(payload.optPermissions);

                const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                let navigatePathname = undefined;
                do {
                    navigatePathname = prevPathname.splice(-1)[0];
                } while (navigatePathname?.startsWith("/Login"));

                sessionStorage.setItem("prevPathname", prevPathname);

                context.setLoggedIn(true);
                context.setLoginProvider(authProvider);
                // console.log("login provider on sign-in : ", authProvider);

                navigate(navigatePathname || "/", { replace: true });
            })
            .catch((e) => {
                dgLogger.error(e)();
                setState(prev => ({ ...prev, popup: POPUP.LoginFailure, popupTarget: { provider: authProvider, error: e }}));
                localStorage.removeItem("autoLogin");
                context.setLoggedOut();
            });
    }, [context, navigate]);

    useEffect(() => {
        document.getElementById("App").className = "login";

        setHandleMobileAppWebviewMessages("login", ({provider, email, password}) => login(provider, email, password));
        setHandleMobileAppWebviewMessages("firebase-auth-failure", ({provider, error}) => {
            setState(prev => ({ ...prev, popup: POPUP.FirebaseAuthFailure, popupTarget: {provider: provider, error: error } }));
        });
        return () => {
            setHandleMobileAppWebviewMessages("firebase-auth-failure", undefined);
            setHandleMobileAppWebviewMessages("login", undefined);
        };
    }, [login]);

    const getLoginFailureMessage = useCallback((provider, error) => {
        if (error.code == E.BANNED) {
            return t("289").split("\n").map((v, i) => <div key={i}>{v}</div>); //banned
        }

        if (provider == "email") {
            return t("288").split("\n").map((v, i) => <div key={i}>{v}</div>); // wrong password
        } else {
            return t("892").replace("[소셜명]", common.capitalizeFirstLetter(provider));
        }
    }, [t]);

    const popupLoginFailure = (body) => {
        const icon = (<img src={process.env.PUBLIC_URL + `/pop_login_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_login_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_login_error@3x.png 3x`} alt="" />);
        const header = <div>{t("28")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupUpdateRealname = (body, payload) => {
        const icon = (<img src={process.env.PUBLIC_URL + `/pop_login_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_login_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_login_error@3x.png 3x`} alt="" />);
        const header = <div>{t("6")}</div>;

        return (
            <Modal
                overrideConfirmText={t("905")}
                onConfirm={() => {
                    Api.logout().finally(() => {
                        localStorage.removeItem("autoLogin");
                        context.setLoggedOut();
                        payload.type = "update-realname";
                        navigate("/Login/UpdateMembership", { state: payload, replace: true });
                    });
                }}
                overrideCancelText={t("415")}
                onCancel={() => {
                    Api.logout().finally(() => {
                        localStorage.removeItem("autoLogin");
                        context.setLoggedOut();
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        switch (state.popup) {
            case POPUP.LoginFailure: {
                const { provider, error } = state.popupTarget;
                const body = getLoginFailureMessage(provider, error);
                return popupLoginFailure(<div>{body}</div>);
            }
            case POPUP.FirebaseAuthFailure: {
                const { provider, error } = state.popupTarget;

                const errorCode = error.code;
                const errorMessage = error.message;
                const errorContext = error.context;
                // The email of the user's account used.
                const email = error.customData?.email;
                errorCode && console.warn("errorCode: ", errorCode);
                errorMessage && console.warn("errorMessage: ", errorMessage);
                errorContext && console.warn("errorContext: ", errorContext);
                email && console.warn("email: ", email);

                const body = t("893").replace("[소셜명]", common.capitalizeFirstLetter(provider));
                return popupLoginFailure(<div>{body}</div>);
            }
            case POPUP.UpdateRealname: {
                const body = <Container>
                    <div className="m-4">{t("906")}</div>
                    <div className="m-4">
                        <ul>
                            <li>{t("907")}</li>
                        </ul>
                    </div>
                    <div>
                        <a href="/PrivacyPolicy" target="_new">{t("6")}</a>
                    </div>
                    </Container>;
                const payload = state.popupTarget;
                return popupUpdateRealname(body, payload);
            }
            case POPUP.GeneralError:
                return <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />;
            case POPUP.None: break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    return context.loggedIn ? (
        <GeneralErrorWindow
            message={state.popupTarget}
            onClick={() => {
                navigate("/");
            }}
        />
    ) : (
        <Container>
            <div className="login-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Login</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t("9")}</span>
            </div>
            {popups()}
            <div className="login-page">
                <div className="login-text notosanskr-500 font-size-36">Login</div>

                <div className="form-group control">
                    <InputText
                        className="signin-email notosanskr-400 font-size-17 ls--0.43px form-control"
                        id="signin-email"
                        name="email"
                        placeholder={t("284")}
                        onBlur={(e) => isValid([e.target])}
                        onKeyPress={(e) => {
                            if (e.key == "Enter") {
                                const email = document.getElementById(ID.email);
                                const password = document.getElementById(ID.password);
                                if (!isValid([email, password])) {
                                    return common.scrollToInvalidElement();
                                }
                                login("email", email.value, password.value);
                            } else common.blankBlocking(e);
                        }}
                    />
                    <span className="icon-email"></span>
                </div>
                <div className="notosanskr-400 font-size-17 c-f00 mb-16px mt-10px">{errors.email?.message}</div>

                <div className="form-group control">
                    <input
                        className="signin-password notosanskr-400 font-size-17 ls--0.43px form-control"
                        type="password"
                        id="signin-password"
                        name="password"
                        placeholder={t("285")}
                        autoComplete="on"
                        onBlur={(e) => isValid([e.target])}
                        onKeyPress={(e) => {
                            if (e.key == "Enter") {
                                const email = document.getElementById(ID.email);
                                const password = document.getElementById(ID.password);
                                if (!isValid([email, password])) {
                                    return common.scrollToInvalidElement();
                                }
                                login("email", email.value, password.value);
                            } else common.blankBlocking(e);
                        }}
                    />
                    <span className="icon-key"></span>
                </div>
                <div className="notosanskr-400 font-size-17 c-f00 mb-16px mt-10px">{errors.password?.message}</div>
                {/* email login */}
                <div>
                    <button
                        className="login notosanskr-400 font-size-17"
                        onClick={() => {
                            const email = document.getElementById(ID.email);
                            const password = document.getElementById(ID.password);
                            if (!isValid([email, password])) {
                                return common.scrollToInvalidElement();
                            }
                            login("email", email.value, password.value);
                        }}
                    >
                        {t("9")}
                    </button>
                </div>

                <div className="signin-auto-login notosanskr-400 font-size-15">
                    <CustomCheckbox
                        type="checkbox"
                        id="signin-auto-login"
                        name="auto-login"
                        // defaultChecked={localStorage.getItem("autoLogin")}
                        onChange={(e) => {
                            if (e.target.checked) {
                                localStorage.setItem("autoLogin", "true");
                            } else {
                                localStorage.setItem("autoLogin", "false");
                            }
                        }}
                    >
                        {t("290")}
                    </CustomCheckbox>
                </div>

                {process.env.REACT_APP_SNS_REGISTER == "true" && (
                    <>
                        <Row className="gx-0">
                            <button
                                className="signin-google-button notosanskr-400 font-size-18 d-flex align-items-center"
                                onClick={() => {
                                    const provider = "google";
                                    if (isRunOnMobileAppWebview()) {
                                        sendMessageToMobileAppWebview({ type: "login", social: true, provider: provider });
                                    } else {
                                        signInWithPopup(auth(), googleProvider())
                                            .then((result) => {
                                                // 참고 : https://cloud.google.com/identity-platform/docs/web/google?hl=ko#signing_in_users_with_the
                                                const user = result.user;
                                                login(provider, user.email, user.uid);
                                            })
                                            .catch((error) => {
                                                setState({ ...state, popup: POPUP.FirebaseAuthFailure, popupTarget: { provider: provider, error: error } });
                                                // The AuthCredential type that was used.
                                                const credential = GoogleAuthProvider.credentialFromError(error);
                                                console.warn("google login error occurred, used credential: ", credential);
                                            });
                                    }
                                }}
                            >
                                <Row className="gx-0 w-100 align-items-center">
                                    <Col className="col-xl-5 col-md-3 col-2 d-flex justify-content-center">
                                        <div className="icon">
                                            <img className="" src={process.env.PUBLIC_URL + `/google_logo.png`} srcSet="/google_logo@2x.png 2x" alt="" />
                                        </div>
                                    </Col>
                                    <Col className="col-xl-7 col-md-7 col-8 d-flex justify-content-xl-start justify-content-center">
                                        {t('891')}
                                    </Col>
                                </Row>
                            </button>
                        </Row>
                        <Row className="gx-0">
                            <button
                                className="signin-apple-button notosanskr-400 font-size-18 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                    const provider = "apple";
                                    if (isRunOnMobileAppWebview()) {
                                        sendMessageToMobileAppWebview({ type: "login", social: true, provider: provider });
                                    } else {
                                        signInWithPopup(auth(), appleProvider())
                                            .then((result) => {
                                                // 참고 : https://cloud.google.com/identity-platform/docs/web/google?hl=ko#signing_in_users_with_the
                                                const user = result.user;
                                                login(provider, user.email, user.uid);
                                            })
                                            .catch((error) => {
                                                setState({ ...state, popup: POPUP.FirebaseAuthFailure, popupTarget: { provider: provider, error: error } });

                                                const credential = OAuthProvider.credentialFromError(error);
                                                console.warn("apple login error occurred, used credential: ", credential);
                                            });
                                    }
                                }}
                            >
                                <Row className="gx-0 w-100 align-items-center">
                                    <Col className="col-xl-5 col-md-3 col-2 d-flex justify-content-center">
                                        <div className="icon">
                                            <img className="" src={process.env.PUBLIC_URL + `/apple_logo.png`} srcSet="/apple_logo@2x.png 2x" alt="" />
                                        </div>
                                    </Col>
                                    <Col className="col-xl-7 col-md-7 col-8 d-flex justify-content-xl-start justify-content-center">
                                        {t('890')}
                                    </Col>
                                </Row>
                            </button>
                        </Row>
                    </>
                )}
                <Row className="gx-0">
                    <Col className="pe-2 notosanskr-400 font-size-16">
                        <CustomButtonLink className="login-password" to="/Login/Password">
                            {t("291")}
                        </CustomButtonLink>
                    </Col>
                    <Col className="ps-2 notosanskr-400 font-size-16">
                        <CustomButtonLink className="login-signup" to="/Login/Signup">
                            {t("292")}
                        </CustomButtonLink>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}
