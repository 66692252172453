import React, { useEffect, useRef, useState, useCallback } from "react";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import { POPUP, VIEW } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import "./ReportDownloadBased.css";
import CustomCheckbox from "../common/CustomCheckbox";
import LoadingSpinner from "../common/LoadingSpinner";
import Modal from "../common/Modal";
import CustomButtonLink from "../common/CustomButtonLink";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

const ID = {
    checkAll: "report-items-check-all",
};

export default function ReportDownloadBased(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [state, setState] = useState({
        isLoading: true,
        isDownloadLoading: false,
        isDownload: false,
        isExcelDownload: false,
        popup: POPUP.None,
        popupTarget: null,
    });
    const dataLength = useRef(-1);
    const filterOption = props.filterOption;
    const basedOption = props.basedOption;
    const paginationOption = useRef({ itemsCountPerPage: 100, pageRangeDisplayed: 10, totalItemsCount: 0 });
    const isMount = useIsMount();
    const navigate = useNavigate();
    const prevFilterOption = useRef({});

    useEffect(() => {
        if (!Object.keys(filterOption).length) {
            paginationOption.current.totalItemsCount = 0;
            setData([]);
        }
    }, [filterOption]);

    const getListBasedDownloadableData = useCallback((page) => {
        setState((prev) => ({ ...prev, isLoading: true }));

        // update startDate
        let newStartDate = null;
        if (filterOption.startDate) {
            // update date into local one
            newStartDate = common.convertToIsoDate(filterOption.startDate);
        }

        // update endDate
        let newEndDate = null;
        if (filterOption.endDate) {
            // update date into local one
            newEndDate = common.convertToIsoDate(filterOption.endDate, 1);
        }

        // 선택된 항목들을 구분자(|)로 나누어 array로 생성
        const groupsArray = filterOption.groups ? filterOption.groups.split("|") : undefined;
        const projectsArray = filterOption.projects ? filterOption.projects.split("|") : undefined;
        const locationsArray = filterOption.locations ? filterOption.locations.split("|") : undefined;

        page = parseInt(page);
        dgLogger.assert(page > 0)();
        const skip = page === 1 || dataLength.current === -1 ? 0 : dataLength.current;
        const limit = (page * paginationOption.current.itemsCountPerPage) - skip;
        dgLogger.assert(limit > 0)();
        const payload = {
            groups: groupsArray,
            projects: projectsArray,
            locations: locationsArray,
            startDate: filterOption.startDate ? newStartDate : undefined,
            endDate: filterOption.endDate ? newEndDate : undefined,
            registered_by: filterOption.name,
            keyword: filterOption.keyword,
            skip: skip,
            limit: limit,
            user_id: filterOption.myReport === "true" ? sessionStorage.getItem("_id") : undefined,
        };

        Api.getDownloadableReportsList(payload)
            .then((payload) => {
                if (!isMount.current) return;
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                const tmpData = page === 1 ? payload.reports : data.concat(payload.reports);
                dataLength.current = tmpData.length;
                setData(() => (tmpData));
            })
            .catch((e) => {
                dgLogger.warn(e)();
                // show empty data
                paginationOption.current.totalItemsCount = 0;
                dataLength.current = -1;
                setData([]);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isLoading: false }));
            });
    }, [filterOption, isMount, data]);

    // page 또는 filterOption이 바뀌었을 때 현재 filterOption에 따라 사용 할 data 요청
    useEffect(() => {
        if (basedOption.page && JSON.stringify(prevFilterOption.current) !== JSON.stringify(filterOption)) {
            getListBasedDownloadableData(basedOption.page);
            prevFilterOption.current = filterOption;
        }
    }, [filterOption, basedOption.page, getListBasedDownloadableData]);

    useEffect(() => {
        resetCheckbox();

        if (document.getElementById("all-check")) {
            if (data.find((item) => item.no_request_history)) {
                document.getElementById("all-check").disabled = false;
            } else {
                document.getElementById("all-check").disabled = true;
            }
        }
    }, [data]);

    /**
     * page를 변경 할 때 실행 되는 callback function
     * page 번호를 url에 넣어 navigate
     */
    const pageChange = useCallback(
        (page) => {
            let param = props.createDefaultParam(VIEW.DownloadBased);
            param += `/page=${page}`;
            navigate(param);
        },
        [navigate, props]
    );

    /**
     * 전체 checkbox를 초기화 해주는 함수
     */
    const resetCheckbox = () => {
        if (!document.getElementById("all-check")) return;
        document.getElementById("all-check").checked = false;
        document.getElementById("request-report-license").disabled = true;
        for (let i = 0; i < paginationOption.current.itemsCountPerPage; i++) {
            if (document.getElementById("report-card-checkbox" + i)) document.getElementById("report-card-checkbox" + i).checked = false;
        }
    };

    const popupDownloadRequest = () => {
        const isExcelonly = state.popupTarget?.isExcelonly ? true : false;
        const icon = <img src={process.env.PUBLIC_URL + `/pop_public_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_public_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_public_complete@3x.png 3x`} alt="" />;
        const header = <div>{isExcelonly ? t("81") : t("77")}</div>;
        const body = <div><LoadingSpinner isOpen={state.isDownloadLoading} />{isExcelonly ? t("82") : t("78")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}

                onConfirm={() => {
                    const reports = state.popupTarget.report_id
                        ? [data.find((item) => item._id === state.popupTarget.report_id)]
                        : data.filter((item) => document.getElementById(item._id).checked);

                    setState({ ...state, isDownloadLoading: true });

                    Api.downloadReports({
                        ids: reports.map(report => report._id),
                        lang: common.getLang()
                    }, isExcelonly)
                        .then(([name, data]) => {
                            const url = window.URL.createObjectURL(data);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", decodeURIComponent(name));
                            link.style.cssText = "display:none";
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        })
                        .catch(e => dgLogger.error(e)())
                        .finally(() => setState({ ...state, isDownloadLoading: false, popup: POPUP.None, popupTarget: null }));
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Request) return popupDownloadRequest();
    };

    if (state.isLoading) return <LoadingSpinner isOpen={true} />;

    return (
        dataLength?.current === 0 ?
            <div id="empty-report-text" className="notosanskr-500 font-size-40 mt-47px" style={{ textAlign: "center" }}>
                {t("689")}
            </div>
            :
        <div className="report-download-based">
            {popups()}
            <table className="result">
                <thead>
                    <tr>
                        <th className="checkbox">
                            <CustomCheckbox
                                id={ID.checkAll}
                                onChange={(e) => {
                                    data.forEach((item) => {
                                        document.getElementById(item._id).checked = e.target.checked;
                                    });

                                    if (e.target.checked) {
                                        setState({ ...state, isDownload: dataLength.current <= 5, isExcelDownload: true });
                                    }
                                    else {
                                        setState({ ...state, isDownload: false, isExcelDownload: false });
                                    }
                                }}
                            ></CustomCheckbox>
                        </th>
                        <th className="title notosanskr-500 font-size-18">{t("656")}</th>
                        <th className="group-name notosanskr-500 font-size-18">{t("76")}</th>
                        <th className="report-date notosanskr-500 font-size-18">{t("27")}</th>
                        <th className="registered-by notosanskr-500 font-size-18">{t("26")}</th>
                        <th className="download-button notosanskr-500 font-size-18">{t("77")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((report) => (
                        <tr key={report._id} className={`notosanskr-500 font-size-16`}>
                            <td className="checkbox">
                                <CustomCheckbox
                                    id={report._id}
                                    onChange={(e) => {
                                        let checked = false;
                                        let checkCount = 0;
                                        data.forEach((item) => {
                                            if (!checked) checked = e.target.checked;
                                            document.getElementById(item._id).checked && checkCount++;
                                        });
                                        if (!checkCount) {
                                            document.getElementById(ID.checkAll).checked = false;
                                            setState({ ...state, isDownload: false, isExcelDownload: false });
                                        }
                                        else if (checkCount > 5) {
                                            setState({ ...state, isDownload: false, isExcelDownload: true });
                                        }
                                        else {
                                            setState({ ...state, isDownload: true, isExcelDownload: true });
                                        }
                                    }}
                                />
                            </td>
                            <td className="title">
                                <CustomButtonLink
                                    to={`/Report/${report._id}`}
                                >
                                    <span className="button-text">{report.title}</span>
                                </CustomButtonLink>
                            </td>
                            <td className="group-name">{common.i18nGroupName(report.group_name)}</td>
                            <td className="report-date">{new Date(report.date).toLocaleDateString()}</td>
                            <td className="registered-by">{report.registered_by}</td>
                            <td className="download-button">
                                <div onClick={() => setState({ ...state, popup: POPUP.Request, popupTarget: { report_id: report._id } })}>
                                    <img src={process.env.PUBLIC_URL + `/icon_data_download.png`} srcSet={`${process.env.PUBLIC_URL}/icon_data_download@2x.png 2x, ${process.env.PUBLIC_URL}/icon_data_download@3x.png 3x`} alt="" />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="text-end button-group notosanskr-16">
                <button
                    className="download-excels"
                    onClick={() => {
                        setState({ ...state, popup: POPUP.Request, popupTarget: { isExcelonly: true } });
                    }}
                    disabled={!state.isExcelDownload}
                >
                    {t("81")}
                </button>
                {/* <button
                    className="download-reports"
                    onClick={() => {
                        setState({ ...state, popup: POPUP.Request, popupTarget: null });
                    }}
                    disabled={!state.isDownload}
                >
                    자료 다운로드
                </button> */}

                {dataLength.current < paginationOption.current.totalItemsCount &&
                    <button
                        className="more"
                        onClick={() => {
                            pageChange(parseInt(basedOption.page) + 1);
                        }}
                    >
                        {t("4")}
                    </button>
                }
            </div>
        </div>
    );
}
